<template>
    <div class="mt-2">
        <div>
            <h2 class="text-xl font-semibold text-center">Abono</h2>
        </div>
        
        <div class="grid grid-cols-3 text-sm my-2">
            <div class="text-left">
                Nº OT:
            </div>
            <div class="text-right col-span-2">
                {{ datos.numeroOT }}
            </div>
            <div class="text-left">
                Fecha:
            </div>
            <div class="text-right col-span-2">
                {{ datos.fecha }}
            </div>
            <div class="text-left">
                Nombre:
            </div>
            <div class="text-right col-span-2">
                {{ datos.nombre }}
            </div>
        </div>
    
        <div class="grid grid-cols-2 mb-2 mt-4">
            <div class="text-left text-sm">
                Monto abono:
            </div>
            <div class="text-right text-sm">
                {{ `$ ${this.formatearCifra(datos.abono)}` }}
            </div>
            <div class="text-left text-xl font-semibold">
                Saldo:
            </div>
            <div class="text-right text-xl font-semibold">
                {{ `$ ${this.formatearCifra(datos.saldo)}` }}
            </div>
        </div>
    </div>
</template>

<script>
import { separarPorMiles } from "@/utils/helpers";

export default {
    data() {
        return {
            codigoBarras: ''
        }
    },
    methods: {
        formatearCifra(cifra) {
            return separarPorMiles(cifra);
        }
    },
    props: {
        datos: {
            type: Object,
            default: null
        }
    }
}
</script>