<template>
  <div class="bg-blue-50 px-6">
    <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
        <div class="px-12 py-6">
            <h1 
            class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal  text-left">
              <faIcon
              icon="ticket-alt"
              type="fas"
              class="text-green-500 text-3xl"
              >
              </faIcon>
              Órdenes de trabajo
            </h1>
            <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Aquí puedes dar seguimiento a las OT ingresadas.</p>
        </div>
    </div>
    <ListTable
      :columns="fields"
      :filters="filters"
      rowsGetter="ot/ordenesTrabajo"
      :buttons="buttons"
      :managementButtons="true"
      :useStorage="true"
      :columnFilters="true"
      @mostrar-detalle="verOT"
      @mostrar-abonos="verAbonos"
      @anular-ot="abrirModalConfirmacionAnular"
      @filter-input="setFilters"
      @dates-input="setDates"
    >
    <template v-slot:estadoLog="data">
      <div :class="`${obtenerClaseEstado(data.value)} flex justify-center items-center px-2 md:whitespace-no-wrap rounded-full font-semibold h-16`">
        <div class="">{{ data.value }}</div>
      </div>
    </template>

    <template v-slot:valorTotal="data">
      <p class="text-gray-900 font-medium text-xs 2xl:text-base whitespace-no-wrap">
        {{ `$ ${formatearCifra(data.value)}` }}
      </p>
    </template>

    <template v-slot:saldo="data">
      <p class="text-gray-900 font-medium text-xs 2xl:text-base whitespace-no-wrap">
        {{ `$ ${formatearCifra(data.value)}` }}
      </p>
    </template>

    </ListTable>
    <Pagination
      path=""
      :meta="meta"
      :links="links"
      action="ot/paginateOrdenesTrabajo"
      v-if="meta && meta.last_page > 1"
    />

    <Modal 
    v-if="modalVisibleOT"
    class="z-40"
    >
      <template v-slot:header>
        <div class="flex justify-between">
          <div class="text-left pl-2 sm:pl-6 sm:pt-3">
              <h3 class="font-medium text-2xl sm:text-3xl text-gray-700">Detalle de la OT</h3>
              <p class="mt-1 max-w-2xl text-sm sm:text-base text-gray-500">Aquí encontrarás la información asociada a esta orden de trabajo.</p>
          </div>
          <div class="text-2xl sm:text-3xl whitespace-no-wrap sm:pt-3 pr-2 sm:pr-6">
            Nº {{ this.otSeleccionadaVer.numeroOt }}
          </div>
        </div>
      </template>

      <template v-slot:body>
        <div>
          <DetalleOT
          :ot="this.otSeleccionadaVer"
          />

          <div class="border-t border-gray-200 py-3">
            <p class="pt-2 text-blue-700 text-lg">
              <faIcon
                icon="exclamation-circle"
                type="fa"
                class="text-blue-700 text-md"
                >
                </faIcon>
                {{ fraseFlujoOT }}
            </p>
          </div>
          <div class="inline-flex pb-1">
              <div class="text-left px-1">
                  <Field 
                    name="estadoOT"
                    as="select"
                    class="form-select w-full mt-1 rounded-md text-2xl border-gray-500"
                    :class="[ otEnMontaje && !isMontaje ? 'text-gray-400': 'text-gray-800' ]"
                    :disabled="otEnMontaje && !isMontaje"
                    v-model="this.estadoSeleccionado"
                    >
                    <option v-for="estado in estadosOTDisponibles" :key="estado" :value="estado"
                    > 
                    {{ estado }} 
                    </option>
                  </Field>
              </div>
              <button 
              @click="abrirModalConfirmarCambioEstado" 
              :disabled="this.estadoSeleccionado == this.otSeleccionadaVer.estadoLog" 
              type="button" 
              class="justify-center rounded-full border border-transparent shadow-sm px-4 text-base font-semibold focus:outline-none sm:ml-3 sm:w-auto sm:text-sm text-white"
              :class="[ otEnMontaje && !isMontaje ? 'bg-gray-400': 'bg-green-600 hover:bg-green-500' ]"
              title="Avanzar etapa"
              >
                  <faIcon 
                  icon="angle-double-right" 
                  type="fa" 
                  class="text-white-700 text-3xl"
                  >
                </faIcon>
              </button>
          </div>
        </div>
      </template>

      <template v-slot:botones>
        <div>
          <div class="flex sm:flex-row-reverse">
              <button @click="this.cerrarModalOt" type="button" class="mt-3 justify-center rounded-md border shadow-sm px-4 py-2 text-base font-semibold focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm border-transparent bg-gray-600 hover:bg-gray-500 text-white">
                Atrás
              </button>
          </div>
        </div>
      </template>
    </Modal>

    <Modal 
    class="z-40"
    v-if="modalVisibleAbono">
      <template v-slot:header>
        <div class="text-left sm:pt-3 sm:pl-6 pl-2">
          <h3 class="font-medium text-2xl sm:text-3xl text-gray-700">Abonos de la orden de trabajo</h3>
          <p class="mt-1 max-w-2xl text-sm sm:text-base text-gray-500">Aquí puedes abonar y ver los abonos para esta Orden de trabajo.</p>
        </div>
      </template>

      <template v-slot:body>
        <AbonosOT
        :abonosOT="this.abonosSeleccionadosVer"
        :keyRecarga="this.keyComponenteAbonos"
        @ingresarBoleta="mostrarFormBoleta"
        />
        <Form @submit="confirmarAbono" :validation-schema="schema">
          <div class="sm:inline-flex pt-8 pb-3">

            <div class="text-center sm:px-2 relative flex flex-col lg:flex-row lg:items-center">
              <label 
              class="text-md text-left md:mr-1 font-normal" 
              :class="this.abonosSeleccionadosVer.saldo == 0 ? 'text-gray-400' : 'text-gray-800'"
              >
              Monto:
              </label>
              <Field 
                name="montoAbono"
                class="form-input rounded-md text-right text-lg border-gray-500"
                :class="this.abonosSeleccionadosVer.saldo == 0 ? 'text-gray-400' : 'text-gray-900'"
                v-model="setMontoAbono"
                onkeypress="return /[0-9]/i.test(event.key)"
                :disabled="this.abonosSeleccionadosVer.saldo == 0"
                >
              </Field>
              <ErrorMessage name="montoAbono" class="block absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right sm:mr-2"/>
            </div>

            <div class="text-center sm:px-2 flex flex-col mt-3 sm:mt-0 lg:flex-row lg:items-center">
              <label 
              class="text-md text-left mr-1 font-normal" 
              :class="this.abonosSeleccionadosVer.saldo == 0 ? 'text-gray-400' : 'text-gray-900'"
              >
              Medio pago:
              </label>
              <Field 
                name="medioPagoAbono"
                as="select"
                class="form-select rounded-md text-md border-gray-500"
                :class="this.abonosSeleccionadosVer.saldo == 0 ? 'text-gray-400' : 'text-gray-900'"
                v-model="this.abono.medioPagoAbono"
                :disabled="this.abonosSeleccionadosVer.saldo == 0"
                >
                <option value="Efectivo"> Efectivo </option>
                <option value="Transbank" selected> Transbank </option>
                <option value="Cheque"> Cheque </option>
                <option value="Convenio"> Convenio </option>
              </Field>
            </div>

            <div class="sm:pl-3 flex items-end">
                <button 
                type="submit" 
                :disabled="this.abonosSeleccionadosVer.saldo == 0"
                class="h-12 px-4 rounded-full border border-transparent shadow-sm text-lg font-normal focus:outline-none sm:text-base text-white mt-6 sm:mt-0 w-full"
                :class="this.abonosSeleccionadosVer.saldo == 0 ? 'bg-gray-400' : 'bg-blue-700 hover:bg-blue-600'"
                >
                  Abonar
                </button>
            </div>
            
          </div>
        </Form>
      </template>

      <template v-slot:botones>
        <div class="flex sm:flex-row-reverse">
              <button @click="this.cerrarModalAbonos" type="button" class="mt-1 justify-center rounded-full border shadow-sm px-4 py-2 text-base font-normal focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm border-transparent bg-gray-600 hover:bg-gray-500 text-white">
                Salir
              </button>
        </div>
      </template>
    </Modal>

    <Modal 
    v-if="esVisibleConfirmacionAbono"
    :titulo="'¡Un momento!'"
    :parrafo="'Vas a ingresar un abono para esta orden de trabajo.'"
    :pregunta="'¿Deseas continuar?'"
    :inhabilitacion="estaInhabilitadoBotonAbono"
    @cerrar="cerrarModalConfirmarAbono"
    @accion="ingresarAbono"
    class="z-40"
    >
    </Modal>

    <Modal
    v-if="esVisibleConfirmacionCambioEstado"
    :titulo="'¡Un momento!'"
    :parrafo="'Vas a cambiar el estado de esta orden de trabajo.'"
    :pregunta="'¿Deseas continuar?'"
    :inhabilitacion="estaInhabilitadoBotonCambioEstado"
    @cerrar="cerrarModalConfirmarCambioEstado"
    @accion="cambiarEstado(this.otSeleccionadaVer.numeroOt, this.estadoSeleccionado)"
    class="z-40"
    >
    </Modal>

    <Modal 
    v-if="esVisibleConfirmacionAnular"
    :titulo="'¡Un momento!'"
    :parrafo="`Vas a anular la OT nº ${numeroOTSeleccionadaAnular}. La anulación de una OT es un proceso irreversible.`"
    :pregunta="'¿Deseas continuar?'"
    :textoBotonAction="'Sí, anular'"
    :colorBotonAccion="'bg-red-700 hover:bg-red-600'"
    :inhabilitacion="estaInhabilitadoBotonAnularOT"
    @cerrar="cerrarModalConfirmacionAnular"
    @accion="anularOt(numeroOTSeleccionadaAnular)"
    class="z-40"
    >
    </Modal>

    <Modal
    titulo="Voucher Abono"
    textoBotonAction="Imprimir"
    class="z-40"
    @accion="imprimirVoucher('voucherAbono')"
    @cerrar="cerrarModalVoucherAbono"
    v-if="esVisibleVoucherAbono"
    >
    <template v-slot:body>
      <Voucher
      id="voucherAbono">
        <template v-slot:contenido>
          <VoucherAbono
          :datos="datosVoucherAbono"
        />
        </template>
      </Voucher>
    </template>
  </Modal>

  <FormBoletas
  v-if="esVisibleFormBoletas"
  @cerrar="cerrarFormBoleta"
  @boletaIngresada="cerrarFormBoleta"
  :numeroOT="this.abonosSeleccionadosVer != null ? this.abonosSeleccionadosVer.numeroOt : 0"
  class="z-40"
  />

  </div>
</template>

<script>
import ListTable from "@/components/ListTable";
import Pagination from "@/components/Pagination";
import Modal from "@/components/Modal";
import DetalleOT from "@/components/OTs/DetalleOT";
import AbonosOT from "@/components/OTs/AbonosOT";
import Voucher from "@/components/Voucher";
import VoucherAbono from "@/components/OTs/VoucherAbono";
import FormBoletas from '@/components/boletas/NuevaBoleta';

import { mapGetters } from "vuex";
import { Field, Form, ErrorMessage } from "vee-validate";
import { string , object } from "yup";
import { separarPorMiles, printSection } from '@/utils/helpers';

import store from "@/store/index";
import OTService from "@/services/OrdenTrabajoService";
import AbonoService from "@/services/AbonoService";

export default {
  name: "ListaOrdenesTrabajo",
  setup() {
    const schema = object({
      montoAbono: string().required('Ingresa un monto.')
    });
    let initials = store.getters['ot/filters']
    let dates = store.getters['ot/dates']
    let estadosOt = [
      'En inicio', 
      'Cristales solicitados',
      'Despachada a Montajes',
      'Recibida en Montajes',
      'Montaje OK',
      'En espera de retiro',
      'Finalizada',
      'Crédito simple', 
      'Cristales recibidos con error',
      'Montaje fallido'
    ];
    let estadosOptions =  estadosOt.map(estado => {
      return {value: estado, text: estado}
    });

    return {
      schema,
      fields: {
        'numeroOt' : 'ot',
        'codigoOt' : 'Código',
        'fechaCreacion' : 'fecha',
        'nombre' : 'Nombre',
        'apellidoPaterno' : 'Apellido p.',
        'apellidoMaterno' : 'Apellido m.',
        'telefono' : 'Teléfono',
        'valorTotal' : 'valor total',
        'saldo' : 'saldo',
        'estadoLog': 'estado'
      },
      filters: [
        {
          "placeholder": "N°",
          "type": "number",
          "field": "ot_num",
          "initial": initials && initials.ot_num ? initials.ot_num.value : ""
        },
        {
          "placeholder": "Cod",
          "type": "number",
          "field": "ot_code",
          "initial": initials && initials.ot_code ? initials.ot_code.value : ""
        },
        {
          "placeholder": "Por fecha",
          "field": "created_at",
          "type": "date-range",
          "initial": dates ? dates : null
        },
        {
          "placeholder": "Por nombre",
          "field": "ot_nombre",
          "initial": initials && initials.ot_nombre ? initials.ot_nombre.value : ""
        },
        {
          "placeholder": "Apellido pat...",
          "field": "ot_apellido_pat",
          "initial": initials && initials.ot_apellido_pat ? initials.ot_apellido_pat.value : ""
        },
        {
          "placeholder": "Apellido mat...",
          "field": "ot_apellido_mat",
          "initial": initials && initials.ot_apellido_mat ? initials.ot_apellido_mat.value : ""
        },
        {
          "type": "none",
        },
        {
          "type": "none"
        },
        {
          "type": "none"
        },
        {
          "placeholder": "Estado",
          "type": "select",
          "field": "estado_log",
          "options": estadosOptions,
          "initial": initials && initials.estado_log ? initials.estado_log.value : ""
        }
      ],
      estadosOt: estadosOt
    }
  },
  data() {
    return {
      rows: [],
      modalVisibleOT: false,
      modalVisibleAbono: false,
      otSeleccionadaVer: null,
      abonosSeleccionadosVer: null,
      numeroOTSeleccionadaAnular: null,
      estadoSeleccionado: null,
      abono: {
        numeroOT: '',
        idUsuario: '',
        montoAbono: 0,
        medioPagoAbono: 'Efectivo'
      },
      esVisibleConfirmacionAbono: false,
      esVisibleConfirmacionCambioEstado: false,
      esVisibleConfirmacionAnular: false,
      esVisibleVoucherAbono : false,
      keyComponenteAbonos: 0,
      estadosOTDisponibles: [],
      estaInhabilitadoBotonAbono: false,
      estaInhabilitadoBotonCambioEstado: false,
      estaInhabilitadoBotonAnularOT: false,
      datosVoucherAbono: {
        numeroOT: 0,
        fecha: '00/00/0000',
        nombre: '',
        abono: 0,
        saldo: 0,
        sucursal: ''
      },
      otEnMontaje: false,
      esVisibleFormBoletas: false,
      estaInhabilitadoBotonIngresarBoleta: false
    }
  },
  computed: {
    ...mapGetters("ot", ["error", "meta", "links"]),
    ...mapGetters("auth", ["isMontaje", "isAdmin"]),
    buttons() {
      let buttons = [
        {
          "text": "Ver",
          "event": "mostrar-detalle"
        }
      ]
      if (!this.isMontaje) {
        buttons.push({
          "text": "Abonos",
          "event": "mostrar-abonos"
        })
      }
      if (this.isAdmin) {
        buttons.push({
          "text": "Anular",
          "event": "anular-ot"
        });
      }
      return buttons
    },
    setMontoAbono: {
      get: function() {
        return `$ ${this.abono.montoAbono.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`  
      },
      set: function(modifiedValue) {
          
          let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""));
          
          if (isNaN(newValue)) {
              newValue = 0;
          }
          
          this.abono.montoAbono = newValue;
      }
    },
    fraseFlujoOT: function() {
      let frase = 'Selecciona una etapa de la lista y modifica el flujo de trabajo de la OT.';
      
      if (this.otEnMontaje && !this.isMontaje) {
        frase = 'La orden de trabajo está en proceso de montaje.';
      }

      return frase;
    }
  },
  methods: {
    mostrarListaOT() {
      store.dispatch("ot/getOrdenesTrabajo", 1);
    },
    verOT(value) {
      OTService.getOT(value.numeroOt).then((response) => {
        this.otSeleccionadaVer = response.data.data;
        this.estadoSeleccionado = this.otSeleccionadaVer.estadoLog;
        if (this.estadoSeleccionado == 'Despachada a Montajes' || this.estadoSeleccionado == 'Recibida en Montajes') {
          this.otEnMontaje = true;
        }
        else{
          this.otEnMontaje = false;
        }
        return this.otSeleccionadaVer;
      }).then((otSeleccionada) => {
        if(otSeleccionada != null){
          this.abrirModalOt();
        }
      });
    },
    verAbonos(value) {
      OTService.getOT(value.numeroOt).then((response) => {
        this.abonosSeleccionadosVer = response.data.data;
        return this.abonosSeleccionadosVer;
      }).then((otSeleccionada) => {
        if(otSeleccionada != null){
          this.abrirModalAbonos();
        }
      });
    },
    obtenerClaseEstado(key) {
      switch (key) {
        case 'Finalizada':
           return 'bg-gray-300 text-gray-800 text-xs 2xl:text-base';

        case 'En espera de retiro':
          return 'bg-green-200 text-green-800 text-xs 2xl:text-base';

        case 'Montaje OK':
          return 'bg-yellow-400 text-yellow-800 text-xs 2xl:text-base';

        case 'Recibida en Montajes':
          return 'bg-blue-300 text-blue-800 text-xs 2xl:text-base'; 

        case 'Despachada a Montajes':
          return 'bg-blue-300 text-blue-800 text-xs 2xl:text-base';

        case 'Crédito simple':
          return 'bg-purple-300 text-purple-800 text-xs 2xl:text-base';

        case 'Cristales solicitados':
          return 'bg-blue-300 text-blue-800 text-xs 2xl:text-base';

        case 'En inicio':
          return 'bg-blue-300 text-blue-800 text-xs 2xl:text-base';

        case 'Montaje fallido':
          return 'bg-red-300 text-red-700 text-xs 2xl:text-base';

        case 'Cristales recibidos con error':
          return 'bg-red-300 text-red-700 text-xs';
      
        default:
          break;
      }
    },
    cerrarModalOt() {
      this.modalVisibleOT = false;
    },
    sugerirEstadosOT() {
      switch (this.estadoSeleccionado) {
        case 'Finalizada':
          this.estadosOTDisponibles = ['Finalizada'];
           break;

        case 'En espera de retiro':
          this.estadosOTDisponibles = ['En espera de retiro', 'Finalizada', 'Crédito simple'];
          break;

        case 'Montaje OK':
          this.estadosOTDisponibles = ['Montaje OK', 'En espera de retiro', 'Montaje fallido'];
          break;

        case 'Recibida en Montajes':
          this.estadosOTDisponibles = ['Recibida en Montajes', 'Montaje OK', 'Montaje fallido', 'Cristales recibidos con error'];
          break; 

        case 'Despachada a Montajes':
          this.estadosOTDisponibles = ['Despachada a Montajes', 'Recibida en Montajes'];
          break;

        case 'Crédito simple':
          this.estadosOTDisponibles = ['Crédito simple', 'Finalizada'];
          break;

        case 'Cristales solicitados':
          this.estadosOTDisponibles = ['Cristales solicitados', 'Despachada a Montajes', 'Cristales recibidos con error'];
          break;

        case 'En inicio':
          this.estadosOTDisponibles = ['En inicio', 'Cristales solicitados'];
          break;

        case 'Montaje fallido':
          this.estadosOTDisponibles = ['Montaje fallido', 'Cristales solicitados', 'Despachada a Montajes'];
          break;

        case 'Cristales recibidos con error':
          this.estadosOTDisponibles = ['Cristales recibidos con error', 'Cristales solicitados'];
          break;
      }
    },
    abrirModalOt() {
      this.sugerirEstadosOT();
      this.modalVisibleOT = true;
    },
    cerrarModalAbonos() {
      this.modalVisibleAbono = false;
    },
    abrirModalAbonos() {
      this.modalVisibleAbono = true;
    },
    abrirModalConfirmarAbono() {
      this.esVisibleConfirmacionAbono = true;
    },
    cerrarModalConfirmarAbono() {
      this.esVisibleConfirmacionAbono = false;
      this.estaInhabilitadoBotonAbono = false;
    },
    abrirModalConfirmarCambioEstado() {
      if (this.estadoSeleccionado == 'Finalizada' && this.otSeleccionadaVer.saldo > 0) {
        this.emitter.emit("notification-set", {
          type: 'warning',
          title: 'Un momento!',
          text: 'Una OT solo puede pasar a "Espera de retiro" cuando el saldo ha sido pagado.',
          time: 10000,
          group: 'notifications'
        });
      }
      else{
        this.esVisibleConfirmacionCambioEstado = true;
      }
    },
    cerrarModalConfirmarCambioEstado() {
      this.esVisibleConfirmacionCambioEstado = false;
      this.estaInhabilitadoBotonCambioEstado = false;
    },
    abrirModalConfirmacionAnular(fila) {
        this.numeroOTSeleccionadaAnular = fila.numeroOt;
        this.esVisibleConfirmacionAnular = true;
    },
    cerrarModalConfirmacionAnular() {
      this.esVisibleConfirmacionAnular = false;
      this.estaInhabilitadoBotonAnularOT = false;
    },
    cambiarEstado(numOt, estadoSeleccionado) {
      this.estaInhabilitadoBotonCambioEstado = true;
      if(estadoSeleccionado == this.otSeleccionadaVer.estadoLog) {
        this.emitter.emit("notification-set", {
            type: 'info',
            title: '¡Ojo!',
            text: 'Tienes que seleccionar un estado diferente al actual.',
            time: 10000,
            group: 'notifications'
          });
      }
      else{             
        OTService.updateOT(numOt, estadoSeleccionado)
        .then(() => this.mostrarListaOT())
        .then(() => this.cerrarModalConfirmarCambioEstado())
        .then(() => this.cerrarModalOt());        
      }
    },
    anularOt(numeroOT) {
      this.estaInhabilitadoBotonAnularOT = true;
      OTService.nullateOT(numeroOT)
      .then(() => this.mostrarListaOT())
      .then(() => this.cerrarModalConfirmacionAnular())
      .then(() => {
        this.emitter.emit("notification-set", {
            type: 'success',
            title: '¡Todo en orden!',
            text: `Has anulado la OT nº ${this.numeroOTSeleccionadaAnular}.`,
            time: 10000,
            group: 'notifications'
          });
      })
      .catch(() => {
        this.cerrarModalConfirmacionAnular();
        this.emitter.emit("notification-set", {
            type: 'error',
            title: '¡Un momento!',
            text: 'Ha ocurrido un imprevisto. Inténtalo de nuevo.',
            time: 10000,
            group: 'notifications'
          });
      });
    },
    ingresarAbono() {
      this.estaInhabilitadoBotonAbono = true;
      this.cerrarModalConfirmarAbono();
      this.error = null;
      this.abono.numeroOT = this.abonosSeleccionadosVer.numeroOt;
      this.abono.idUsuario = this.abonosSeleccionadosVer.idUsuario;

      const payload = this.abono;
      AbonoService.storeAbono(payload)
      .then((response) => {
        let abonoIngresado = response.data;
        this.datosVoucherAbono.fecha = abonoIngresado.fechaFormateada;
        OTService.getOT(this.abonosSeleccionadosVer.numeroOt)
        .then((response) => {
          this.abonosSeleccionadosVer = response.data.data;
        })
        .then(() => {
          this.recargarModalAbonos();
          this.datosVoucherAbono.saldo = this.abonosSeleccionadosVer.saldo;
          this.datosVoucherAbono.numeroOT = this.abonosSeleccionadosVer.numeroOt;
          this.datosVoucherAbono.nombre = `${this.abonosSeleccionadosVer.nombre} ${this.abonosSeleccionadosVer.apellidoPaterno} ${this.abonosSeleccionadosVer.apellidoMaterno}`;
          this.datosVoucherAbono.abono = this.abono.montoAbono;

          this.esVisibleVoucherAbono = true;
          this.resetearAbono();
          this.mostrarListaOT();
        });
        this.emitter.emit("notification-set", {
          type: 'success',
          title: '¡Todo en orden!',
          text: 'El abono ha sido ingresado al sistema.',
          time: 10000,
          group: 'notifications'
        });
      })
      .catch(() => {
        this.cerrarModalConfirmarAbono();
        this.resetearAbono();
        this.emitter.emit("notification-set", {
          type: 'error',
          title: '¡Un momento!',
          text: 'Ha ocurrido un imprevisto. Inténtalo de nuevo.',
          time: 10000,
          group: 'notifications'
        });
      });
    },
    resetearAbono() {
        this.abono.numeroOT = '',
        this.abono.idUsuario = '',
        this.abono.montoAbono = 0,
        this.abono.medioPagoAbono = 'Efectivo'
    },
    recargarModalAbonos() {
      this.keyComponenteAbonos+= 1;
    },
    confirmarAbono() {
      if (this.abonosSeleccionadosVer.saldo > 0 && this.abonosSeleccionadosVer.saldo >= this.abono.montoAbono && this.abono.montoAbono != 0) {
        this.abrirModalConfirmarAbono();
      }
      else{
        this.emitter.emit("notification-set", {
          type: 'error',
          title: '¡Un momento!',
          text: 'No es posible realizar el abono. Verifica los datos ingresados y el saldo.',
          time: 10000,
          group: 'notifications'
        });
      }
    },
    cerrarModalVoucherAbono() {
      this.esVisibleVoucherAbono = false;
      this.limpiarCamposVoucherAbono();
    },
    limpiarCamposVoucherAbono() {
      this.datosVoucherAbono.numeroOT = 0;
      this.datosVoucherAbono.fecha = '00/00/0000';
      this.datosVoucherAbono.nombre = '';
      this.datosVoucherAbono.telefono = '';
      this.datosVoucherAbono.abono = 0;
      this.datosVoucherAbono.saldo = 0;
    },
    setDates(dates) {
      store.dispatch("ot/setDates", dates);
      store.dispatch("ot/getOrdenesTrabajo", 1);
    },
    setFilters(filters) {
      store.dispatch("ot/setFilters", filters);
      this.mostrarListaOT();
    },
    formatearCifra(cifra) {
      return separarPorMiles(cifra);
    },
    imprimirVoucher(idElemento) {
      printSection(idElemento);
    },
    mostrarFormBoleta() {
      this.esVisibleFormBoletas = true;
    },
    cerrarFormBoleta() {
      this.esVisibleFormBoletas = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1
    store.dispatch("ot/getOrdenesTrabajo", currentPage).then(() => {
      to.params.page = currentPage
      next()
    })
  },
  components: {
    ListTable,
    Pagination,
    Modal,
    DetalleOT,
    Field,
    Form,
    ErrorMessage,
    AbonosOT,
    Voucher,
    VoucherAbono,
    FormBoletas
  }
}
</script>