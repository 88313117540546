<template>
<div class="fixed inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 sm:rounded-lg">

        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="w-full lg:w-auto inline-block align-bottom bg-white rounded-xl text-center shadow-xl transform transition-all sm:my-8 sm:align-middle">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-xl">
                <div class="sm:items-start">
                    <div class="mt-3 sm:mt-0 text-xl text-gray-800 font-normal">
                        Generar boleta
                    </div>
                </div>
            </div>
            <div class="bg-white pb-4 sm:px-6 sm:pb-4 border-t border-b border-gray-300">
                <div class="sm:items-start">
                    <div class="px-6 pt-6">
                        <Form @submit="ingresarBoleta" :validation-schema="schema">
                            <div class="flex flex-col">
                                <div class="pb-8 flex justify-center">
                                    <div class="flex flex-col text-center px-2 relative text-lg max-w-sm">
                                        <div class="flex items-center pt-4">
                                            <label class="font-normal text-gray-800">Nº boleta:</label>
                                        </div>
                                        <div class="relative">
                                            <Field 
                                                name="numeroBoleta"
                                                class="form-input rounded-md text-left text-lg w-full border-gray-500 text-gray-900"
                                                v-model="datosBoleta.numeroBoleta"
                                                onkeypress="return /[0-9]/i.test(event.key)"
                                                > 
                                            </Field>
                                            <ErrorMessage name="numeroBoleta" class="block absolute text-sm text-red-600 inset-x-0 text-right mr-2"/>
                                        </div>
                                        <div class="flex items-center pt-4">
                                            <label class="font-normal text-gray-800">Monto:</label>
                                        </div>
                                        <div class="relative">
                                            <Field 
                                                name="montoBoleta"
                                                class="form-input rounded-md text-left text-lg w-full border-gray-500 text-gray-900"
                                                v-model="setMontoBoleta"
                                                onkeypress="return /[0-9]/i.test(event.key)"
                                                > 
                                            </Field>
                                            <ErrorMessage name="montoBoleta" class="block absolute text-sm text-red-600 inset-x-0 text-right mr-2"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div class="px-4 py-3 sm:px-6 rounded-lg">
                <div class="justify-center">
                    <button 
                    @click="ingresarBoleta"
                    :disabled="estaInhabilitadoBotonIngresarBoleta"
                    class="rounded-full border border-transparent shadow-md mx-1 px-10 py-3 text-sm font-normal focus:outline-none sm:ml-3 sm:w-auto bg-green-600 hover:bg-green-500 text-white">
                        ¡Sí!
                    </button>
                    <button 
                    @click="$emit('cerrar'), habilitarBotonIngresarBoleta(), resetearBoleta()"
                    class="rounded-full border shadow-md px-6 py-3 text-sm font-normal focus:outline-none sm:mt-0 sm:w-auto border-transparent bg-gray-600 hover:bg-gray-500 text-white">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { Field, Form, ErrorMessage } from "vee-validate";
import { string , object } from "yup";

import BoletaService from '@/services/BoletaService';

export default {
    setup() {
        const schema = object({
            numeroBoleta: string().required('Ingresa un n° de boleta.'),
            montoBoleta: string().required('Ingresa un monto.')
        });

        return {
            schema
        }
    },
    data() {
        return {
            datosBoleta: {
                numeroOT: 0,
                numeroBoleta: '',
                monto: 0
            },
            estaInhabilitadoBotonIngresarBoleta: false
        }
    },
    computed: {
        setMontoBoleta: {
            get: function() {
                return `$ ${this.datosBoleta.monto.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`  
            },
            set: function(modifiedValue) {
                
                let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""));
                
                if (isNaN(newValue)) {
                    newValue = 0;
                }
                
                this.datosBoleta.monto = newValue;
            }
        }
    },
    methods: {
        ingresarBoleta() {
            this.inhabilitarBotonIngresarBoleta();

            if (this.datosBoleta.numeroBoleta == '') {
                this.emitter.emit("notification-set", {
                type: 'error',
                title: '¡Un momento!',
                text: 'Debes completar los dos campos para ingresar la boleta al sistema.',
                time: 10000,
                group: 'notifications'
                });
            }
            else {
                if (this.datosBoleta.monto == 0) {
                   this.emitter.emit("notification-set", {
                    type: 'error',
                    title: '¡Un momento!',
                    text: 'No puedes ingresar una boleta con monto igual a $0.',
                    time: 10000,
                    group: 'notifications'
                    }); 
                }
                else {
                    let payload =  this.datosBoleta;

                    BoletaService.registrarBoleta(payload).then(() => {
                        this.$emit('boletaIngresada');
                        this.habilitarBotonIngresarBoleta();
                        this.resetearBoleta();

                        this.emitter.emit("notification-set", {
                        type: 'success',
                        title: '¡Todo en orden!',
                        text: 'La boleta fue ingresada al sistema.',
                        time: 10000,
                        group: 'notifications'
                        });
                    })
                    .catch(() => {
                        this.habilitarBotonIngresarBoleta();

                        this.emitter.emit("notification-set", {
                        type: 'error',
                        title: '¡Un momento!',
                        text: 'Ha ocurrido un imprevisto. Inténtalo de nuevo.',
                        time: 10000,
                        group: 'notifications'
                        });
                    });
                }
            }  
        },
        habilitarBotonIngresarBoleta() {
            this.estaInhabilitadoBotonIngresarBoleta = false
        },
        inhabilitarBotonIngresarBoleta() {
            this.estaInhabilitadoBotonIngresarBoleta = false
        },
        resetearBoleta() {
            this.datosBoleta.numeroOT = 0;
            this.datosBoleta.numeroBoleta = '';
            this.datosBoleta.monto = 0;
        }
    },
    props: {
        numeroOT: {
            type: Number,
            default: null
        }
    },
    mounted() {
        this.datosBoleta.numeroOT = this.numeroOT;
    },
    components: {
        Field,
        Form,
        ErrorMessage
    }
}
</script>