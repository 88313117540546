<template :keyRecarga>
<div>
  <div class="border-b border-blue-400 pb-2">
    <div class="grid sm:grid-cols-2 md:grid-cols-4 grid-cols-1 w-full pb-6">
      <div class="text-left relative">
        <label class="text-lg font-medium text-gray-600">Numero OT:</label>
      </div>
      <div class="text-lg font-medium text-gray-900 text-left">
        {{ abonosOT.numeroOt }}
      </div>
      <div class="text-left relative">
        <label class="text-md font-medium text-gray-600">Estado:</label>
      </div>
      <div class="text-lg font-medium text-gray-900 text-left">
        {{ abonosOT.estadoLog }}
      </div>
      <div class="text-left relative">
        <label class="text-lg font-medium text-gray-600">Fecha:</label>
      </div>
      <div class="text-lg font-medium text-gray-900 text-left">
        {{ abonosOT.fechaCreacion }}
      </div>
      <div class="text-left relative">
        <label class="text-lg font-medium text-gray-600">Hora:</label>
      </div>
      <div class="text-lg font-medium text-gray-900 text-left">
        {{ abonosOT.horaCreacion }}
      </div>
      <div class="text-left relative">
        <label class="text-lg font-medium text-gray-600">Nombre cliente:</label>
      </div>
      <div class="text-lg font-medium text-gray-900 text-left">
        {{ abonosOT.nombre }}
      </div>
      <div class="text-left relative">
        <label class="text-lg font-medium text-gray-600">Apellidos:</label>
      </div>
      <div class="text-lg font-medium text-gray-900 text-left">
        {{ `${abonosOT.apellidoPaterno} ${abonosOT.apellidoMaterno}` }}
      </div>  
    </div>

    <div class="pt-6 border-gray-200 border-t">
      <h2 class="text-xl text-blue-700">Abonos de esta OT</h2>
      <div class="shadow rounded-lg overflow-x-auto">
        <table class="w-full text-base divide-y divide-gray-200 whitespace-no-wrap overflow-hidden">
          <thead class="bg-blue-500 text-white">
            <tr>
              <th class="py-1 px-2">Nº</th>
              <th class="py-1 px-2">Fecha</th>
              <th class="py-1 px-2">Hora</th>
              <th class="py-1 px-2">Medio pago</th>
              <th class="py-1 px-2">Monto</th>
            </tr>
          </thead>
          <tbody class="text-gray-900">
            <tr v-for="(abono, index) in abonosOT.abonos" :key="index" class="border border-gray-200">
              <td class="py-1 px-2 border border-blue-200 sm:border-none">{{ index + 1 }}</td>
              <td class="py-1 px-2 border border-blue-200 sm:border-none">{{ abono.fecha }}</td>
              <td class="py-1 px-2 border border-blue-200 sm:border-none">{{ abono.hora }}</td>
              <td class="py-1 px-2 border border-blue-200 sm:border-none">{{ abono.medioPago }}</td>
              <td class="py-1 px-2 border border-blue-200 sm:border-none">{{ `$ ${this.formatearCifra(abono.montoAbono)}` }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      
      <div class="grid grid-cols-5 pt-4">
        <div class="self-center justify-self-start col-span-1">
          <button class="text-blue-500 hover:text-blue-400 focus:outline-none"
          @click="$emit('ingresarBoleta')"
          >
            Generar boleta
          </button>
        </div>
        <div class="sm:inline-flex whitespace-no-wrap col-span-3">
          <div class="flex mx-2">
            <div class="px-2">
              <label class="text-xl font-medium text-gray-800">Valor total:</label>
            </div>
            <div class="px-2 w-full text-right">
              <label class="text-xl font-medium text-gray-700">{{ `$ ${this.formatearCifra(abonosOT.valorTotal)}` }}</label>
            </div>
          </div>
          <div class="flex mx-2">
            <div class="px-2">
              <label class="text-xl font-medium text-gray-800">Saldo:</label>
            </div>
            <div class="px-2 w-full text-right">
              <label class="text-xl font-medium text-blue-700">{{ `$ ${this.formatearCifra(abonosOT.saldo)}` }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { separarPorMiles } from '@/utils/helpers';

export default {
  name: 'AbonosOT',
  props: {
    abonosOT: {
      type: Object,
      default: null
    },
    keyRecarga: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    formatearCifra(valor) {
      return separarPorMiles(valor);
    },
  },
  components: {
    
  },
  mounted() {
    
  }
}
</script>